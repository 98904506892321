<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-chart_of_account-text</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Chart Of Accounts ({{ totalChartOfAccounts }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        @click="handleNewAccount"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Account
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="entry-permission-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.index"
            :ref="item.index"
            :disabled="defaultStatus !== item.index && loading"
            @click="handleTabClick(item.index)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="chartOfAccounts"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalChartOfAccounts"
        @click:row="editItem"
        @update:options="fetch"
      >
        <template v-slot:item.is_active="{ item }">
          <v-checkbox
            v-model="item.is_active"
            disabled
            readonly
            class="mt-0 mb-0"
          />
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialogUpdate" max-width="600px">
      <v-form ref="form" class="form__form" @submit.prevent="submit">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ headerModal }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Account Name</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    placeholder="Account Name"
                    v-model="form.name"
                    :error-messages="form.$getError('name')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  ></v-text-field>

                  <label class="text-field-label">Account Number</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    type="number"
                    placeholder="Account Number"
                    v-model="form.number"
                    :error-messages="form.$getError('number')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  ></v-text-field>

                  <label class="text-field-label">Category</label>
                  <SelectCategory
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.category_id"
                    :error-messages="form.$getError('category_id')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  />

                  <label class="text-field-label">Tax Status</label>
                  <v-checkbox
                    v-model="form.tax_inc"
                    hide-details="auto"
                    label="Inc. Tax"
                    class="mt-0 mb-6"
                  ></v-checkbox>

                  <label class="text-field-label">Description</label>
                  <v-textarea
                    flat
                    solo
                    required
                    hide-details="auto"
                    class="mt-2"
                    placeholder="Description"
                    v-model="form.description"
                    :error-messages="form.$getError('description')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  ></v-textarea>

                  <v-checkbox
                    v-model="form.is_active"
                    hide-details="auto"
                    label="Set as Active"
                    class="mt-4"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogUpdate()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submit()"
              name="submit"
              :loading="form.$busy"
              :disabled="!form.name"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * ChartOfAccount list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import Form from '@/utils/form'
import SelectCategory from '@/components/fields/SelectCategory.vue'

export default {
  name: 'ChartOfAccountsPage',
  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    SelectCategory,
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'header',
      sortOrder: true,
      headers: [
        {
          text: 'Account Name',
          value: 'name',
        },
        {
          text: 'Number',
          value: 'number',
          align: 'center',
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
      ],
      tabItems: [
        { tab: 'Active', index: 0 },
        { tab: 'Archived', index: 1 },
      ],
      selected: [],
      actions: [],
      options: {},
      form: new Form({
        category_id: '',
        name: '',
        number: '',
        description: '',
        tax_inc: false,
        is_active: false,
      }),
      dialogUpdate: false,
      isUpdate: false,
      idUpdated: '',
      defaultStatus: 0,
    }
  },

  created() {
    this.fetchInitParams()
    this.clearChartOfAccounts()
    this.fetch(this.options)
  },

  computed: {
    ...mapState({
      chartOfAccounts: (state) => state.chartOfAccount.list,
      listMeta: (state) => state.chartOfAccount.listMeta,
      permissions: (state) => state.auth.permissions,
      totalChartOfAccounts: (state) =>
        state.chartOfAccount.totalChartOfAccounts,
      params: (state) => state.chartOfAccount.params,
    }),

    submitApi() {
      return this.isUpdate
        ? this.updateChartOfAccount
        : this.createChartOfAccount
    },

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    headerModal() {
      return this.isUpdate ? 'Edit Account' : 'Create Account'
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.CHART_OF_ACCOUNTS_CREATE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getChartOfAccounts: 'chartOfAccount/getChartOfAccounts',
      createChartOfAccount: 'chartOfAccount/createChartOfAccount',
      updateChartOfAccount: 'chartOfAccount/updateChartOfAccount',
    }),

    ...mapMutations({
      clearChartOfAccounts: 'chartOfAccount/clearChartOfAccountList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'chartOfAccount/setParams',
    }),

    filterList(filterValue) {
      this.clearChartOfAccounts()
      this.fetch(this.options, filterValue)
    },

    activeAction() {
      this.clearChartOfAccounts()
      this.fetch(this.options)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.options, this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearChartOfAccounts()
      this.fetch(this.options)
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        options: {
          page: options?.page || 1,
          itemsPerPage: options?.itemsPerPage
            ? options?.itemsPerPage === -1
              ? this.totalTags
              : options.itemsPerPage
            : 25,
          sort: options?.sortBy[0]
            ? options?.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '',
        },
        status: this.defaultStatus,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.setParams(params)

      this.loading = true
      await this.getChartOfAccounts(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    editItem(item) {
      this.dialogUpdate = true
      this.isUpdate = true
      this.idUpdated = item.id
      this.form.name = item.name
      this.form.number = item.number
      this.form.tax_inc = item.tax_inc
      this.form.category_id = item.category_id
      this.form.description = item.description
      this.form.is_active = item.is_active
    },

    resetForm() {
      this.form = new Form({
        category_id: '',
        name: '',
        number: '',
        description: '',
        tax_inc: false,
        is_active: false,
      })
    },

    handleNewAccount() {
      this.dialogUpdate = true
      this.isUpdate = false
      this.resetForm()
    },

    cancelDialogUpdate() {
      this.dialogUpdate = false
      this.resetForm()
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.showSnackbar('Chart Of Account details successfully updated!')
          } else {
            this.showSnackbar('Chart Of Account successfully created!')
          }
          this.form.$busy = false
          this.dialogUpdate = false
          this.isUpdate = false
          this.form = new Form({
            category_id: '',
            name: '',
            number: '',
            description: '',
            tax_inc: false,
            is_active: false,
          })
          this.fetch()
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()

      if (this.isUpdate) {
        form.id = this.idUpdated
      }

      return form
    },

    fetchInitParams() {
      this.defaultStatus = this.params.status

      this.options = this.params.options

      this.filter.search = this.params.search
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'created_at'
      this.activeAction()
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
